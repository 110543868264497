<template>
  <ApiLoadingController :fetch="fetch" :params="matchId">
    <template #default="{ data }">
      <MatchSpectatorVideo :round-num="roundNum" :vod-info="data" />
    </template>

    <template #no-data>
      <MatchVideoPage :match-data="matchData.info" :match-id="matchId" :round-num="roundNum" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'

import { getMatchReplayInfo } from '../../api/matches/index.js'
import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import MatchSpectatorVideo from '../../components/match/SpectatorVideo.vue'

import MatchVideoPage from './Video.vue'

export default {
  name: 'MatchSpectatorVideoPage',
  components: { MatchSpectatorVideo, ApiLoadingController, MatchVideoPage },
  props: {
    matchId: px.string.isRequired,
    roundNum: px.number,
  },
  computed: {
    isCollegiate() {
      return this.$route.params.isCollegiate || false
    },
  },
  inject: ['matchData'],
  methods: {
    async fetch(matchId, config) {
      try {
        return await getMatchReplayInfo({ matchId, isCollegiate: this.isCollegiate }, config)
      } catch (error) {
        if (error.response?.status === 404) {
          return null
        }
        throw error
      }
    },
  },
}
</script>

<style scoped></style>
