<template>
  <div class="embed-responsive embed-responsive-16by9">
    <component
      v-if="vodInfo.vod_id"
      :is="replayComponent"
      :current-time="startTime"
      :id="vodInfo.vod_id"
      :start-time="startTime"
      :end-time="endTime"
    />
  </div>
</template>

<script>
import px from 'vue-types'

import { pxNullable } from '../Map/types.js'
import TwitchReplay from '../replay/TwitchReplay.vue'
import YoutubeReplay from '../replay/YoutubeReplay.vue'

export default {
  name: 'MatchSpectatorVideo',
  props: {
    roundNum: pxNullable(px.integer),
    vodInfo: px.shape({
      platform: px.oneOf(['twitch', 'youtube']),
      timestamps: px.arrayOf(
        px.shape({
          start_millis: px.number.isRequired,
          duration_millis: px.number.isRequired,
        })
      ).isRequired,
      vod_id: px.string,
    }).loose.isRequired,
  },
  computed: {
    replayComponent() {
      switch (this.vodInfo.platform) {
        case 'twitch':
          return TwitchReplay
        case 'youtube':
          return YoutubeReplay
        default:
          throw new Error(`Unsupported video platform ${this.vodInfo.platform}`)
      }
    },
    roundInfo() {
      if (this.roundNum == null) {
        const firstRound = this.vodInfo.timestamps[0]
        const lastRound = this.vodInfo.timestamps.slice(-1)[0]
        return {
          start_millis: firstRound.start_millis,
          duration_millis: lastRound.start_millis + lastRound.duration_millis - firstRound.start_millis,
        }
      }
      return this.vodInfo.timestamps[this.roundNum]
    },
    startTime() {
      return this.roundInfo.start_millis
    },
    endTime() {
      return this.startTime + this.roundInfo.duration_millis
    },
  },
}
</script>

<style scoped></style>
